@import "main.css";
.bloc-slider-homepage > h1 { color : rgb(77, 89, 96) !important;
font-weight : bold !important;
}

@media (max-width: 992px) { 
.fs-2.titleText {
margin-top: 50vh !important;
font-size : 1.4em !important;
padding: 0% !important;
 }
}

h3.my-0.fw-normal.fs-5.grow.titles {
    color: black;
}

/*------------------------------------------Bouton Fixe en bas à droite------------------------------*/
.btnFixe {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    width: 30px;
    transition: width 0.5s ease;
}

.btnFixe:hover {
    width: 40px;
    transition: width 0.5s ease;
}